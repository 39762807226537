import webApi from "./api";
import qs from "querystring";

export class AuthService {
    login(user) {
        let loginParam = {
            key: process.env.VUE_APP_API_KEY,
            login_id: user.username,
            login_password: user.password,
        };
        return webApi.post('auth_login', qs.stringify(loginParam));
    }

    logout(user_id) {
        if (!user_id) return Promise.reject("Error...");

        return webApi.post('logout', {
            userId: user_id,
        });
    }
}

const authService = new AuthService();

export default authService;
