<template>
  <div class="sm-screen">
    <div class="bg-gradient">
      <div class="circle-top"></div>
      <div class="circle-bottom"></div>
      <div class="container content">
        <div class="image">
          <img class="my-4" src="@/assets/images/logo.svg" alt="">
        </div>
        <span class="login-title">{{ $t('login.input_field.karute') }}</span>
        <form class="mt-5" @keyup.enter="login()">
          <div class="form-group">
            <input type="text"
                   v-model="user.username"
                   :state="$v.user.username.$dirty ? !$v.user.username.$error : null"
                   :placeholder="$t('login.input_field.userid.label')">
<!--            <b-form-invalid-feedback :state="$v.user.username.$dirty ? !$v.user.username.$error : null">-->
<!--              Username is required.-->
<!--            </b-form-invalid-feedback>-->
            <input type="password"
                   v-model="user.password"
                   :state="$v.user.password.$dirty ? !$v.user.password.$error : null"
                   :placeholder="$t('login.input_field.password.label')">
<!--            <b-form-invalid-feedback :state="$v.user.password.$dirty ? !$v.user.password.$error : null">-->
<!--              Password is required.-->
<!--            </b-form-invalid-feedback>-->
          </div>
<!--          <p class="mt-2 text-right">Forgot Password?</p>-->
<!--          <div class="d-flex justify-content-start mt-4">-->
<!--            <b-form-checkbox-->
<!--                id="checkbox-remember-me"-->
<!--                v-model="rememberMe"-->
<!--                name="checkbox-remember-me"-->
<!--                :value="true"-->
<!--                :unchecked-value="false"-->
<!--            >-->
<!--              Remember me-->
<!--            </b-form-checkbox>-->
<!--          </div>-->
          <button type="button" @click="login" class="button-login bg-white mt-4">{{ $t('login.buttons.login') }}</button>
        </form>
<!--        <p class="mb-0 mt-2 pb-3">Need an account? Sign Up</p>-->
      </div>
    </div>

    <div>
      <b-modal id="modal-center" centered hide-header hide-footer @keyup.enter="selectShop()">
        <header class="modal-header">
          <button type="button" aria-label="Close" class="close" @click="closeModal()">
            <img src="@/assets/images/icons/ic_exit.svg" alt="">
          </button>
        </header>
        <div class="flexible-height">
          <b-dropdown :text="shopSelect.shop_name || $t('login.choose_shop')"
                      class="m-2"
                      :variant="(!$v.shopSelect.required && $v.shopSelect.$dirty) ? 'outline-danger' : ($v.shopSelect.required ? 'outline-success' : '')"
                      v-click-out="clickOutDropdown"
                      block>
            <b-dropdown-item v-for="(shop, i) in shops"
                             :key="i"
                             :active="shop.shop_id === shopSelect.shop_id"
                             @click="getShop(shop)">
              {{ shop.shop_name }}
            </b-dropdown-item>
          </b-dropdown>

          <b-form-invalid-feedback :state="$v.shopSelect.$dirty ? !$v.shopSelect.$error : null" class="m-2">
            {{ $t('login.messages.shop_required') }}
          </b-form-invalid-feedback>
        </div>
        <div class="d-flex justify-content-center m__top--20">
          <button type="button" @click="selectShop" class="button-login bg-pink mb-1">{{ $t('login.buttons.login') }}</button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AuthService from '../../services/auth'
import {required} from 'vuelidate/lib/validators'
import {ACTION_LOGIN,SET_LOCATIONS} from "@/store/auth/actions";

export default {
  name: "Login",
  data() {
    return {
      user: {
        username: '',
        password: ''
      },
      shops: [],
      shopSelect: {},
      rememberMe: false,
    }
  },
  validations: {
    user: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
    shopSelect: {
      required
    }
  },
  methods: {
    closeModal() {
      this.$root.$emit("bv::hide::modal", "modal-center");
    },
    clickOutDropdown() {
      document.getElementsByClassName('dropdown-menu')[0].classList.remove('show')
      document.getElementsByClassName('flexible-height')[0].classList.remove('fix-height-162')
    },
    login() {
      this.$v.user.$touch()
      if (this.$v.user.username.$dirty && !this.$v.user.username.required && this.$v.user.password.$dirty && !this.$v.user.password.required) {
        this.$toast.error('ユーザー名とパスワードを入力してください。')
      } else if (this.$v.user.username.$dirty && !this.$v.user.username.required) {
        this.$toast.error('ユーザー名を入力してください。')
      } else if (this.$v.user.password.$dirty && !this.$v.user.password.required) {
        this.$toast.error('パスワードを入力してください。')
      }
      if (!this.$v.user.$invalid) {
        AuthService.login(this.user).then((res) => {
          let result = res.data.result;
          localStorage.setItem('locations',JSON.stringify(result))
          this.$store.dispatch(SET_LOCATIONS, result)
          if (result.length > 1) {
            this.shops = result;
            this.$root.$emit("bv::show::modal", "modal-center");
            this.$nextTick(() => {
              document.getElementsByClassName('dropdown-menu')[0].classList.add('show')
              document.getElementsByClassName('flexible-height')[0].classList.add('fix-height-162')
            })
          } else {
            this.shopSelect = result[0];
            this.$store.dispatch(ACTION_LOGIN, this.shopSelect).then(() => {
              this.$router.push({name: 'home'}).then(() => {
                // this.$toast.success('Login success');
              });
            })
          }
        }).catch(() => {
          this.$toast.error(this.$t('login.messages.login_fail'))
        })
      }
    },
    getShop(shop) {
      this.shopSelect = {...shop}
      if (document.getElementsByClassName('dropdown-menu') && document.getElementsByClassName('dropdown-menu')[0]) {
        document.getElementsByClassName('dropdown-menu')[0].classList.remove('show')
        document.getElementsByClassName('flexible-height')[0].classList.remove('fix-height-162')
      }
    },
    selectShop() {
      this.$v.shopSelect.$touch()
      if (!this.$v.shopSelect.$invalid) {
        this.$store.dispatch(ACTION_LOGIN, this.shopSelect).then(() => {
          this.$router.push({name: 'home'}).then(() => {
            // this.$toast.success('Login success');
          });
        })
      }
    }
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', () => {
      if (document.getElementsByClassName('flexible-height')[0]) {
        document.getElementsByClassName('flexible-height')[0].classList.add('fix-height-162')
      }
    })
    this.$root.$on('bv::dropdown::hide', () => {
      if (document.getElementsByClassName('flexible-height')[0]) {
        document.getElementsByClassName('flexible-height')[0].classList.remove('fix-height-162')
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.sm-screen {
  //max-width: 768px;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
}

.bg-gradient {
  position: relative;
  background-image: linear-gradient(#E7779E, #E95074);
  width: 100%;
  min-height: 100vh;
  z-index: 0;
}

.bg-gradient .circle-top {
  width: 400px;
  height: 400px;
  background: #f9a1b9;
  position: absolute;
  border-radius: 50%;
  top: -150px;
  right: calc(-7% - 10rem);
  z-index: -1;
  opacity: 0.4;
}

.bg-gradient .circle-bottom {
  width: 350px;
  height: 350px;
  background: #f9a1b9;
  position: absolute;
  border-radius: 50%;
  bottom: -200px;
  left: -10rem;
  z-index: -1;
  opacity: 0.4;
}

.content {
  color: #ffffff;

  .login-title {
    font-size: 1.5rem;
  }

  input {
    border-radius: 0 !important;
  }
}

.image {
  min-height: 35vh;
  display: flex;
  align-items: center;
}

.image img {
  width: 60%;
  margin: 0 auto;
}

@media screen and (min-width: 576px) {
  form .form-group {
    max-width: 576px;
    margin: 0 auto;
  }
}

form input {
  width: 100%;
  border: none;
  border-bottom: #FFC4E6 solid 1px;
  margin-top: 20px;
  background: transparent;
  color: #ffffff;
}

form input:focus {
  outline: none;
}

form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}

form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ffffff;
}

form input::-ms-input-placeholder { /* Microsoft Edge */
  color: #ffffff;
}

input.remember {
  width: auto;
  margin-top: 5px;
}

button.button-login {
  padding: 8px 60px;
  border: none;
  border-radius: 40px;
  font-weight: bold;
  box-shadow: 0 4px 8px #b1b1b1;
}

button.button-login.bg-white {
  background: #ffffff;
  color: $pink;
  box-shadow: 0 4px 8px #c52c50;
}

button.button-login.bg-pink {
  background: $pink;
  color: #ffffff;
}

button.button-login.bg-white:focus {
  outline: none;
  box-shadow: 0 0 3pt 2pt #e55488;
}

button.button-login.bg-pink:focus {
  outline: none;
  box-shadow: 0 0 3pt 2pt #d2d2d2;
}

.flexible-height {
  min-height: 80px;
  transition: min-height 0.3s ease-in-out;
}

.fix-height-162 {
  min-height: 162px !important;
  transition: min-height 0.3s ease-in-out;
}

/*Custom bootstrap modal*/
::v-deep .modal-content {
  border-radius: 26px !important;
}

::v-deep .modal-header {
  border-bottom: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

::v-deep .modal-header {
  .close {
    line-height: 18px;

    img {
      width: 26px !important;
    }
  }
}

form {
  input:not([type='checkbox']) {
    margin-top: 40px !important;
  }
}

::v-deep {
  .custom-control-input:focus ~ .custom-control-label {
    &::before {
      box-shadow: unset !important;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: url("~@/assets/images/icons/ic_check.svg") !important;
    }
  }

  .custom-control-label {
    &::before {
      border-color: #FFC4E6 !important;
      background: transparent !important;
      width: 1.1rem;
      height: 1.1rem;
      top: 0.12rem;
    }

    &::after {
      width: 1.1rem;
      height: 1.1rem;
      top: 0.12rem;
    }
  }

  @media screen and (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 0.5rem auto !important;
    }
  }

  .dropdown {
    button {
      background-color: $white !important;
      color: $dark !important;

      &::after {
        position: absolute;
        right: 8px;
        top: 18px;
      }
    }

    ul {
      width: 100%;
      padding: 0;
      max-height: 122px;
      overflow-y: auto;

      .dropdown-item {
        min-height: 40px;
        align-items: center;
        display: flex;
        background-color: #F2D6DE;
        border-top: 1px solid $white;

        &.active {
          background-color: #E95074;
        }
      }
    }
  }

  .container {
    padding-right: 25px;
    padding-left: 25px;
  }
}
</style>
